import { Outlet, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Loading from './components/Loading';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Gallery = lazy(() => import('./pages/Gallery'));
const Prices = lazy(() => import('./pages/Prices'));
const Contacts = lazy(() => import('./pages/Contacts'));
const Items = lazy(() => import('./pages/Items'));
const Services = lazy(() => import('./pages/Services'));
const Navbar = lazy(() => import('./components/Navbar'));
const Footer = lazy(() => import('./components/Footer'));

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<NavWrapper />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/prices' element={<Prices />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/items' element={<Items />} />
        </Route>
      </Routes>
    </>
  );
}

function NavWrapper() {
  return (
    <div className='flex flex-col min-h-screen'>
      <Navbar />
      <Suspense fallback={<Loading />}>
        <Outlet />
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
